var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"four-img"},[(_vm.list.length > 0)?_c('div',{staticClass:"four-img-main"},[_c('div',{staticClass:"title"},[_vm._v(" — "),_c('span',[_vm._v(_vm._s(_vm.Title))])]),_c('div',{staticClass:"four-img-list"},[_c('el-row',{attrs:{"gutter":15}},[_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"four-img-list-left",on:{"click":function($event){return _vm.handleToCompPage(_vm.list[0])}}},[_c('img',{attrs:{"src":_vm.pic + _vm.list[0].Detail.Pic,"alt":""}}),_c('p',{style:({
								color: _vm.list[0].Detail.FontColor == 1 ? '#000000' : '#ffffff'
							})},[_vm._v(" "+_vm._s(_vm.list[0].Detail.Des)+" ")])])]),_c('el-col',{attrs:{"span":12}},[_c('el-row',{attrs:{"gutter":15}},[_c('el-col',{attrs:{"span":12}},[_c('div',{staticClass:"four-img-list-center",on:{"click":function($event){return _vm.handleToCompPage(_vm.list[1])}}},[_c('img',{attrs:{"src":_vm.pic + _vm.list[1].Detail.Pic,"alt":""}}),_c('p',{style:({
										color: _vm.list[1].Detail.FontColor == 1 ? '#000000' : '#ffffff'
									})},[_vm._v(" "+_vm._s(_vm.list[1].Detail.Des)+" ")])])]),_c('el-col',{attrs:{"span":12}},[_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"four-img-list-right",on:{"click":function($event){return _vm.handleToCompPage(_vm.list[2])}}},[_c('img',{attrs:{"src":_vm.pic + _vm.list[2].Detail.Pic,"alt":""}}),_c('p',{style:({
												color: _vm.list[2].Detail.FontColor == 1 ? '#000000' : '#ffffff'
											})},[_vm._v(" "+_vm._s(_vm.list[2].Detail.Des)+" ")])])]),_c('el-col',{attrs:{"span":24}},[_c('div',{staticClass:"four-img-list-right four-img-list-right-last",on:{"click":function($event){return _vm.handleToCompPage(_vm.list[3])}}},[_c('img',{attrs:{"src":_vm.pic + _vm.list[3].Detail.Pic,"alt":""}}),_c('p',{style:({
												color: _vm.list[3].Detail.FontColor == 1 ? '#000000' : '#ffffff'
											})},[_vm._v(" "+_vm._s(_vm.list[3].Detail.Des)+" ")])])])],1)],1)],1)],1)],1)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }