<template>
	<div class="commodity">
		<div class="commodity-main">
			<el-row :gutter="20">
				<el-col :span="6" v-for="(item, index) in list" :key="index">
					<div class="commodity-list" @click="handleToCompPage(item)">
						<el-card class="commodity-card" shadow="never" :body-style="{ padding: '10px' }"><img :src="pic + item.Detail.Pic" class="image" style="height:250px" /></el-card>
						<div class="commodity-text">
							<div class="name">{{ item.ProData.ProName }}</div>
							<div class="sku">SKU: {{ item.ProData.BarCode }}</div>
							<div class="bottom">
								<div class="bottom-top">
									<div class="price">￥{{ item.ProData.Price }}</div>
									<div v-if="item.ProData.PLUSPrice > 0 && item.ProData.Price != item.ProData.PLUSPrice" class="PLUS">
										￥{{ item.ProData.PLUSPrice }}
										<img src="../../../assets/plus.png" @click="toplus" alt="" />
									</div>
								</div>
								<div class="bottom-bottom">
									<div>
										<span class="original">￥{{ item.ProData.InTaxPrice }}</span>
									</div>
									<div class="sold">已售{{ item.ProData.VirtualSales }}件</div>
								</div>
							</div>
							<div class="border"></div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import { receiveCoupon } from '../service';
export default {
	props: ['text'],
	data() {
		return {
			pic: FILE_URL,
			Title: '',
			list: []
		};
	},
	methods: {
		toplus() {
			if (!localStorage.getItem('userToken')) {
				this.toCIAMLoginIn()	
				// localStorage.setItem('u', '');
				// this.$message.success('正在跳转登录');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
			} else {
				window.open(`/me/plus`, '_blank').location;
			}
		}
		// handleToPage(data) {
		//     if (
		//         data.Detail.Type == 2 ||
		//         data.Detail.Type == 3 ||
		//         data.Detail.Type == 4
		//     ) {
		//         // 商品列表
		//         window.open(
		//             `/product-list?type=${data.Detail.Type}&link=${data.Detail.LinkID}`,
		//             '_blank'
		//         ).location
		//     } else if (data.Detail.Type == 1) {
		//         let u = localStorage.getItem('u')
		//         window.open(
		//             `/commodity?pid=${data.Detail.LinkID}&u=${u}`,
		//             '_blank'
		//         ).location
		//     } else if (data.Detail.Type == 5) {
		//         window.open(data.Title, '_blank').location
		//     } else if (data.Detail.Type == 6) {
		//         if (!localStorage.getItem('userToken')) {
			//this.toCIAMLoginIn('请先登录！')	
		// 	//localStorage.setItem('u', '')
		//      //       this.$message.success('正在跳转登录')
		//        //     setTimeout(() => {
		//          //       let url = encodeURI(window.location.href)
		//           //      window.location.href = `${URL_LOGIN}?returnurl=${url}`
		//           //  }, 1000)
		//         } else {
		//             receiveCoupon({ DisID: data.Detail.LinkID }).then(res => {
		//                 if (res.code == 0) {
		//                     this.$message.error(res.msg)
		//                 } else {
		//                     this.$message.success(res.msg)
		//                 }
		//             })
		//         }
		//     }
		// },
		// handleToVideo(data) {
		//     window.open(data.Title, '_blank').location
		// }
	},
	mounted() {
		this.Title = this.text.Title;
		this.list = this.text.DetailInfo;
	}
};
</script>

<style lang="less" scoped>
.commodity {
	.commodity-main {
		width: 1240px;
		margin: 30px auto;
	}

	.commodity-card {
		cursor: pointer;

		img {
			width: 100%;
		}
	}

	.commodity-text {
		margin-top: 20px;

		.code {
			font-size: 14px;
		}

		.name {
			font-size: 14px;
			font-family: 'nl';
		}

		.sku {
			margin: 8px 0;
			font-size: 12px;
			color: #333333;
		}
		.bottom {
			.bottom-top {
				display: flex;
				.price {
					color: #1b98aa;
					font-family: 'ct';
					font-size: 18px;
				}
				.PLUS {
					color: #000000;
					font-family: 'ct';
					font-size: 18px;
					margin-left: 10px;
				}
			}
			.bottom-bottom {
				display: flex;
				justify-content: space-between;
				.original {
					color: #666666;
					text-decoration: line-through;
				}
				.sold {
					color: #666666;
				}
			}
		}

		.border {
			height: 2px;
			margin-top: 10px;
			background: #bbbfbe;
		}
	}

	.commodity-list {
		position: relative;
		height: 440px;

		.star {
			width: 30px;
			height: 30px;
			line-height: 30px;
			position: absolute;
			top: 20px;
			right: 20px;
			text-align: center;
			background: #eeeeee;
			border-radius: 5px;
			display: none;

			img {
				width: 15px;
				height: 15px;
				margin-top: 7px;
			}
		}
	}

	.commodity-list:hover {
		.border {
			height: 2px;
			background: linear-gradient(50deg, #60b689, #00b5c2, #0897b4);
		}

		.star {
			display: block;
		}
	}
}
</style>
