<template>
	<div class="four-img">
		<div class="four-img-main" v-if="list.length > 0">
			<div class="title">
				—
				<span>{{ Title }}</span>
			</div>
			<div class="four-img-list">
				<el-row :gutter="15">
					<el-col :span="12">
						<div class="four-img-list-left" @click="handleToCompPage(list[0])">
							<img :src="pic + list[0].Detail.Pic" alt="" />
							<p
								:style="{
									color: list[0].Detail.FontColor == 1 ? '#000000' : '#ffffff'
								}"
							>
								{{ list[0].Detail.Des }}
							</p>
						</div>
					</el-col>
					<el-col :span="12">
						<el-row :gutter="15">
							<el-col :span="12">
								<div class="four-img-list-center" @click="handleToCompPage(list[1])">
									<img :src="pic + list[1].Detail.Pic" alt="" />
									<p
										:style="{
											color: list[1].Detail.FontColor == 1 ? '#000000' : '#ffffff'
										}"
									>
										{{ list[1].Detail.Des }}
									</p>
								</div>
							</el-col>
							<el-col :span="12">
								<el-row>
									<el-col :span="24">
										<div class="four-img-list-right" @click="handleToCompPage(list[2])">
											<img :src="pic + list[2].Detail.Pic" alt="" />
											<p
												:style="{
													color: list[2].Detail.FontColor == 1 ? '#000000' : '#ffffff'
												}"
											>
												{{ list[2].Detail.Des }}
											</p>
										</div>
									</el-col>
									<el-col :span="24">
										<div class="four-img-list-right four-img-list-right-last" @click="handleToCompPage(list[3])">
											<img :src="pic + list[3].Detail.Pic" alt="" />
											<p
												:style="{
													color: list[3].Detail.FontColor == 1 ? '#000000' : '#ffffff'
												}"
											>
												{{ list[3].Detail.Des }}
											</p>
										</div>
									</el-col>
								</el-row>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
import { receiveCoupon } from '../service';

export default {
	props: ['text'],
	data() {
		return {
			pic: FILE_URL,
			Title: '',
			list: []
		};
	},
	methods: {
		// handleToPage(data) {
		// 	if (data.Detail.Type == 2 || data.Detail.Type == 3 || data.Detail.Type == 4) {
		// 		window.open(`/product-list?type=${data.Detail.Type}&link=${data.Detail.LinkID}`, '_blank').location;
		// 	} else if (data.Detail.Type == 1) {
		// 		let u = localStorage.getItem('u');
		// 		window.open(`/commodity?pid=${data.Detail.LinkID}&u=${u}`, '_blank').location;
		// 	} else if (data.Detail.Type == 5) {
		// 		window.open(data.Title, '_blank').location;
		// 	} else if (data.Detail.Type == 6) {
		// 		if (!localStorage.getItem('userToken')) {
			//this.toCIAMLoginIn()	
		//// 			localStorage.setItem('u', '');
		// //			this.$message.success('正在跳转登录');
		////			setTimeout(() => {
		// 	//			let url = encodeURI(window.location.href);
		// 	//			window.location.href = `${URL_LOGIN}?returnurl=${url}`;
		// 	//		}, 1000);
		// 		} else {
		// 			receiveCoupon({ DisID: data.Detail.LinkID }).then(res => {
		// 				if (res.code == 0) {
		// 					this.$message.error(res.msg);
		// 				} else {
		// 					this.$message.success(res.msg);
		// 				}
		// 			});
		// 		}
		// 	}
		// }
	},
	mounted() {
		this.Title = this.text.Title;
		this.list = this.text.DetailInfo;
	}
};
</script>

<style lang="less" scoped>
.four-img {
	.four-img-main {
		width: 1240px;
		margin: 30px auto;

		.title {
			font-size: 20px;
			font-family: 'ct';
			color: #333333;

			span {
				margin-left: 10px;
			}
		}

		.four-img-list {
			margin-top: 20px;

			.four-img-list-left {
				position: relative;
				overflow: hidden;
				img {
					width: 100%;
					height: 300px;
					cursor: pointer;
				}

				p {
					font-size: 20px;
					font-family: 'ct';
					position: absolute;
					right: 20px;
					top: 20px;
					color: #fff;
				}
			}

			.four-img-list-center {
				position: relative;
				overflow: hidden;
				img {
					width: 100%;
					height: 300px;
					cursor: pointer;
				}

				p {
					font-size: 20px;
					font-family: 'ct';
					position: absolute;
					right: 20px;
					top: 20px;
					color: #fff;
				}
			}

			.four-img-list-right {
				position: relative;
				overflow: hidden;
				img {
					width: 100%;
					height: 144px;
					cursor: pointer;
				}

				p {
					font-size: 20px;
					font-family: 'ct';
					position: absolute;
					left: 20px;
					top: 20px;
					color: #000000;
				}
			}

			.four-img-list-right-last {
				overflow: hidden;
				img {
					margin-top: 10px;
					height: 143px;
				}
			}
		}
	}
}
img:hover {
	-webkit-transform: scale(1.1, 1.1);
	-ms-transform: scale(1.1, 1.1);
	transform: scale(1.1, 1.1);
	transition: all 1s;
}
</style>
