<template>
	<div class="three-video">
		<div class="three-video-main">
			<div class="title">
				—
				<span>{{ Title }}</span>
			</div>
			<div class="three-video-list">
				<el-row :gutter="10">
					<el-col :span="8" v-for="(item, index) in list" :key="index">
						<div class="three-video-list-img" @click="handleToCompPage(item)">
							<img @click="handleToVideo(item.Detail)" :src="pic + item.Detail.Pic" alt="" />
							<i class="iconfont iconbofang-moren"></i>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
import { receiveCoupon } from '../service';

export default {
	props: ['text'],
	data() {
		return {
			pic: FILE_URL,
			Title: '',
			list: []
		};
	},
	methods: {
		// handleToPage(data) {
		// 	if (data.Detail.Type == 2 || data.Detail.Type == 3 || data.Detail.Type == 4) {
		// 		window.open(`/product-list?type=${data.Detail.Type}&link=${data.Detail.LinkID}`, '_blank').location;
		// 	} else if (data.Detail.Type == 1) {
		// 		let u = localStorage.getItem('u');
		// 		window.open(`/commodity?pid=${data.Detail.LinkID}&u=${u}`, '_blank').location;
		// 	} else if (data.Detail.Type == 5) {
		// 		window.open(data.Title, '_blank').location;
		// 	} else if (data.Detail.Type == 6) {
		// 		if (!localStorage.getItem('userToken')) {
			//this.toCIAMLoginIn()	
		//// 			localStorage.setItem('u', '');
		// //			this.$message.success('正在跳转登录');
		// //			setTimeout(() => {
		// //				let url = encodeURI(window.location.href);
		// 	//			window.location.href = `${URL_LOGIN}?returnurl=${url}`;
		// 	//		}, 1000);
		// 		} else {
		// 			receiveCoupon({ DisID: data.Detail.LinkID }).then(res => {
		// 				if (res.code == 0) {
		// 					this.$message.error(res.msg);
		// 				} else {
		// 					this.$message.success(res.msg);
		// 				}
		// 			});
		// 		}
		// 	}
		// },
		// handleToVideo(data) {
		// 	window.open(data.Title, '_blank').location;
		// }
	},
	mounted() {
		this.Title = this.text.Title;
		this.list = this.text.DetailInfo;
	}
};
</script>

<style lang="less" scoped>
.three-video {
	.three-video-main {
		width: 1240px;
		margin: 30px auto;

		.title {
			font-size: 20px;
			font-family: 'ct';
			color: #333333;

			span {
				margin-left: 10px;
			}
		}

		.three-video-list {
			cursor: pointer;
			margin-top: 20px;
		}

		.three-video-list-img {
			position: relative;

			img {
				width: 100%;
			}

			i {
				cursor: pointer;
				position: absolute;
				bottom: 10px;
				right: 10px;
				font-size: 30px;
				color: #fbfeff;
			}
		}
	}
}
</style>
