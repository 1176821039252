<template>
	<div class="pic" v-if="list.length > 0"><img :src="pic + list[0].Detail.Pic" alt="" @click="handleToCompPage(list[0])" /></div>
</template>

<script>
import { receiveCoupon } from '../service';
export default {
	props: ['text'],
	data() {
		return {
			pic: FILE_URL,
			list: []
		};
	},
	methods: {
		// handleToPage(data) {
		// 	if (data.Detail.Type == 2 || data.Detail.Type == 3 || data.Detail.Type == 4) {
		// 		window.open(`/product-list?type=${data.Detail.Type}&link=${data.Detail.LinkID}`, '_blank').location;
		// 	} else if (data.Detail.Type == 1) {
		// 		let u = localStorage.getItem('u');
		// 		window.open(`/commodity?pid=${data.Detail.LinkID}&u=${u}`, '_blank').location;
		// 	} else if (data.Detail.Type == 5) {
		// 		window.open(data.Title, '_blank').location;
		// 	} else if (data.Detail.Type == 6) {
		// 		if (!localStorage.getItem('userToken')) {
			//this.toCIAMLoginIn('请先登录！')	
		// //			localStorage.setItem('u', '');
		// 	//		this.$message.success('正在跳转登录');
		// //			setTimeout(() => {
		// //				let url = encodeURI(window.location.href);
		// //				window.location.href = `${URL_LOGIN}?returnurl=${url}`;
		// 	//		}, 1000);
		// 		} else {
		// 			receiveCoupon({ DisID: data.Detail.LinkID }).then(res => {
		// 				if (res.code == 0) {
		// 					this.$message.error(res.msg);
		// 				} else {
		// 					this.$message.success(res.msg);
		// 				}
		// 			});
		// 		}
		// 	}
		// }
	},
	mounted() {
		this.list = this.text.DetailInfo;
	}
};
</script>

<style lang="less" scoped>
.pic {
	width: 1240px;
	margin: 0 auto;

	img {
		width: 100%;
		cursor: pointer;
	}
}
</style>
