import { post } from '@/http'

export function getData(data) {
    return new Promise((resolve, reject) => {
        post('/Home/GetHome', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
// 领取优惠券
export function receiveCoupon(data) {
    return new Promise((resolve, reject) => {
        post('/Discount/ReceiveCoupon', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
