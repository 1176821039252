<template>
	<div><component :is="item.component" :text="item.text" v-for="(item, index) in items" :key="index"></component></div>
</template>

<script>
/* eslint-disable */

import carousel from './components/carousel';
import fourImg from './components/four-img';
import threeImg from './components/three-img';
import threeVideo from './components/three-video';
import fourCommodity from './components/four-commodity';
import manyCommodity from './components/many-commodity';
import pic from './components/pic';
import { getData } from './service';

export default {
	data() {
		return {
			items: [],
			DetailList: []
		};
	},
	components: {
		carousel,
		fourImg,
		threeImg,
		fourCommodity,
		manyCommodity,
		threeVideo,
		pic
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			//成功回调函数停止加载
			getData({ Type: 2 }).then(res => {
				this.DetailList = res.data.DetailList;
				for (const detailListKey in this.DetailList) {
					switch (this.DetailList[detailListKey].ModelID) {
						case 9: // 轮播图
							this.items.push({
								component: carousel,
								text: this.DetailList[detailListKey]
							});
							break;
						case 13: // 4张图片展示
							if (this.DetailList[detailListKey].DetailInfo[0].ProData) {
								// 商品
								this.items.push({
									component: fourCommodity,
									text: this.DetailList[detailListKey]
								});
							} else {
								// 图片
								this.items.push({
									component: fourImg,
									text: this.DetailList[detailListKey]
								});
							}
							break;
						case 12: // 3张图片展示
							this.items.push({
								component: threeImg,
								text: this.DetailList[detailListKey]
							});
							break;
						case 14: // 多张图片商品
							this.items.push({
								component: manyCommodity,
								text: this.DetailList[detailListKey]
							});
							break;
						case 17: // 3张视频
							this.items.push({
								component: threeVideo,
								text: this.DetailList[detailListKey]
							});
							break;
						case 11: // 广告
							this.items.push({
								component: pic,
								text: this.DetailList[detailListKey]
							});
							break;
					}
				}
			});
		}
	}
};
</script>

<style lang="less"></style>
